<template>
    <div class="video-container">
      <video
        ref="videoElement"
        class="fullscreen-video"
        autoplay
        loop
        muted
        controls
      >
        <source src="../assets/compressedAdSubtitles.mp4" type="video/mp4" />
        <!-- Add additional source elements for different video formats if needed -->
        Your browser does not support the video tag.
      </video>
    </div>
  </template>
  
  <script>
  export default {
    mounted() {
      this.$nextTick(() => {
        // Access the video element and set its width to the window width
        const videoElement = this.$refs.videoElement;
        videoElement.style.width = window.innerWidth + 'px';
  
        // Listen for window resize events and update the video width accordingly
        window.addEventListener('resize', this.handleResize);
      });
    },
    beforeDestroy() {
      // Remove the window resize event listener to prevent memory leaks
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        const videoElement = this.$refs.videoElement;
        videoElement.style.width = window.innerWidth + 'px';
      },
    },
  };
  </script>
  
  <style scoped>
  .video-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    background: black;
    margin-top: -5px;
    padding-top: 20px;
    padding-bottom: 40px;
  }
  
  .fullscreen-video {
    width: 90%;
    height: auto;
    position: relative;
    max-width: 800px;
  }
  </style>
  