<template>
  <div>
    <HeaderComp />
    <b-jumbotron class="jumboDark">
      <b-container>
        <b-row class="justify-content-center" style="margin-bottom: 60px">
          <b-col cols="12" style="margin-top: 60px">
            <div class="about_img main_header"></div>
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row class="justify-content-center">
          <b-col cols="12">
            <h1 class="ttl" > 
              <font color="#8eb6c7" > ABOUT </font>
              <font color="#d8e030"> US </font>
            </h1>
          </b-col>  
        </b-row>
        <b-row class="justify-content-center" style="margin-bottom: 60px">
          <b-col cols="11" md="9">
            <p style="text-align: center; font-size: larger">
              Our coaches are just as unique as our training philosophy. Both
              will help you succeed.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-jumbotron>
    <b-container>
      <b-row class="justify-content-center" style="margin-top: 60px">
        <b-col cols="8" md="6">
          <h1 class="ttl">PHILOSOPHY</h1>
          <div class="lineBreak" style="margin-top: -10px"></div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center" style="margin-top: 20px">
        <p style="width: 80%">
          We are lucky to be situated in Boulder, Colorado the crossroads of
          adventure enthusiast, weekend warriors and everyday "life-jugglers".
          Our space keeps training consistent, safe and diverse while allowing
          us to keep doing all the other things we love.
        </p>
        <p style="width: 80%">
          Our philosophy for programming lies within traditional barbell
          training, calisthenics and functional movement. You will see a lot of
          new and creative spins on the classics. Strength training is NOT a
          "one size fits all" and our approach celebrates the uniqueness of
          human bodies. Our periodic (mesocycle) programming is designed to suit
          the newbies, the gym rats and the pros.
        </p>
      </b-row>
    </b-container>
    <CoachesBanner />
    <b-jumbotron class="jumboDark" style="padding: 80px auto">
      <h1 class="ttl" style="padding-top: 30px; font-size: 2rem">
        WANT TO KNOW MORE?
      </h1>
      <b-btn @click="$router.push('contact')"> Contact Us </b-btn>
    </b-jumbotron>
    <button @click="scrollToTop" class="scroll-to-top"><b-icon-arrow-up/></button>
    <FooterComp />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue";
import FooterComp from "@/components/FooterComp.vue";
import CoachesBanner from "@/components/CoachesBanner.vue";

export default {
  name: "AboutView",
  components: {
    HeaderComp,
    FooterComp,
    CoachesBanner,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.about_img {
  background-image: url('../assets/img_coaches_wide.png');
}

</style>
