<template>
    <div>
        <b-jumbotron class="banner" >
            <!-- <h1  class="ttl"> Member Testimonials </h1> -->
            <b-row class="justify-content-center">
                <b-col cols="10" md="6" > 
                    <h1 class="ttl"> MEMBER TESTIMONIALS </h1>
                    <div class="lineBreak" style="margin-top: -10px;"></div>
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-col cols="10" md="5" >
                    <p>
                        "This place is the perfect gym. The coaches are amazing. They really really know their craft 
                        and they teach and encourage and push you with kindness and humor. The other folks at the small 
                        classes support one another and some have become friends. It has a sense of community. 
                        The place is clean, well equipped, and well organized. There is so much value in learning 
                        good form, steady progress, 
                        and variety in a clean safe fun place. Love this place and people!!!"
                    </p>
                    <p> <b>- Rory</b></p>
                </b-col>
                <b-col cols="10" md="5" >
                    <p>
                        "Here are a few reasons why this is the best gym!
                        It’s for everyone. Whether you have never lifted anything in your life or you’re at the highest level in your sport, this is the place for you.
                        Whether you want to train alone, one on one, or in a group class, they offer all of that too!
                        The trainers are very knowledgeable and know how to push you to your best.
                        The owners are consistently looking for ways to improve.
                        It’s always clean! The equipment is top tier.

                        And maybe best of all, the community they have built is exactly what’s in their name, it’s UPLIFTing!" 
                    </p>
                    <p> <b>- Fabi</b></p>
                </b-col>
                <b-col cols="10" md="5" >
                    <p>
                        "I've been going to this gym since 2018 and I can't say enough great things. 
                        The owners Jena and Devin are fantastic and the coaches give you personal 
                        attention even in a class. And the people who go to this gym are great too! 
                        Morning classes get my day going and if I can't make it to a morning class 
                        the evening classes make my nights better! Love Uplift!"
                    </p>
                    <p> <b>- Lori</b></p>
                </b-col>
                <b-col cols="10" md="5" >
                    <p>
                        "Best gym ever! Truly. The coaches are so great! They are knowledgeable, approachable, and supportive. 
                        The community at Uplift is what makes this gym stand out from the rest. Everyone is truly welcomed and 
                        I had never experienced that at any gym. Never feel bored or stagnant here, 
                        I continue to learn so much and have come farther then I ever could have imagined."
                    </p>
                    <p> <b>- Lisa</b></p>
                </b-col>
            </b-row>

       
        
        
        </b-jumbotron>
    </div>
</template>
<script>

export default {
    name: 'TestimonialsBanner2',
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>
<style scoped lang="scss">
.banner {
    padding-top: 40px;
    padding-bottom: 40px;
}

</style>