<template>
    <div>
        <b-jumbotron class="poilcies">
            <h1 class="policy_title" > Membership Policies </h1>
              <h4 class="policy_title"> Personal Training </h4>
              <p class="policy_details"> 
                  
                  We require a minimum of a 24 hour notice prior to session start time or cancellations or 
                  modifications to session. Failure to do so will result in a full session rate fee. 
                  Exceptions include: emergencies and illness. 
              </p>
              <h4 class="policy_title"> Class Reservations </h4>
              <p class="policy_details">
                  To modify or cancel your class reservation without penalty, we require a minimum of a 8 
                  hour notice prior to class start time. Failure to do so will result in a $10 late cancel/no 
                  show fee that is donated to the local charity of the month. Exceptions include: emergencies and 
                  illness.
              </p>
              <h4 class="policy_title"> Class Late Policy </h4>
              <p class="policy_details">
                  To respect the safety of our members, doors will close 5 minutes after class start times.  Failure to arrive
                  before this time will result in a $10 fee that is donated to the local charity of the month
              </p>
              <h4 class="policy_title"> Group Class and Open Access Memberships</h4>
              <p class="policy_details">
                  These memberships run on a month-to-month basis. No contracts here, however we do need the 
                  following to pause or cancel the memberships: 
              </p>
              <h4 class="policy_title"> Pausing Your Membership </h4>
              <p class="policy_details">
                  1 free pause a year for up to 3 months given a 14 day written notice prior to billing cycle date. 
                  Failure to give adequate notice will result in full monthly membership rate fee. Additional pause 
                  requests within the same calendar year will be charged a $45, non refundable fee. 
              </p>
              <h4 class="policy_title"> Cancellation Policy </h4>
              <p class="policy_details">    
                  To cancel your membership we require a 30 day written notice prior to the billing cycle date. 
                  Failure to give adequate notice will result in full monthly membership rate fee. 
      
              </p>
          </b-jumbotron>
    </div>
</template>

<script>
export default {
    name: 'PolicyBanner',
}
</script>
<style lang="scss" scoped>
.poilcies {
    background-color:black;
    color: white;
    display: block;
    text-align: left;
    padding-left: 10%;
    padding-top: 30px;
    padding-bottom: 30px;
}
.policy_title {
    font-variant-caps: all-small-caps;
    color:rgb(230, 228, 228);
}
.policy_details {
    width: 90%;
}
</style>
