<template>
    <div >
      <b-container style="margin-top:60px;">
        <b-row class="justify-content-center" >
          <b-col cols="10" md="8"> 
            <h1 class="ttl"> MEMBERSHIP OPTIONS </h1>
            <div class="lineBreak" style="margin-top: -10px;"></div>
          </b-col>
        </b-row>
        
      </b-container>
      <b-container>
        <b-row class="justify-content-center align-items-center" style="margin-bottom: 20px;">
          <b-col cols="11"  md="4">
            <img  src="../assets/sqr_group_class.png"  class="memImg">
            
          </b-col>
          <b-col cols="11"  md="6">
            <h1 class="memTtl"> Group Class </h1>
            <ul style="text-align: left; margin-left: 10px;">
              <li> Unlimited group classes (capped at 12)</li>
              <li> Discounted personal training session ($49) </li>
              <li> Track your progress with TrainHeroic </li>
              <li> Bring a friend to weekend or holiday classes </li>
              <li> Discounted (or free) attendance to in-house workshops </li>
            </ul>
            <h3 class="price">$229 - $259/mo</h3>
          </b-col>
        </b-row>
        <b-row class="justify-content-center align-items-center" style="margin-bottom: 20px;">
          <b-col cols="11" md="4">
            <img  src="../assets/sqr_pt.png"  class="memImg">
          </b-col>
          <b-col  cols="11" md="6">
            <h1 class="memTtl"> Personal Training </h1>
            <ul style="text-align: left; margin-left: 10px;">
              <li> 1:1 attention with your coach </li>
              <li> Custom programming catered to your goals </li>
              <li> Create a schedule that is convenient for you </li>
              <li> Discounted (or free) attendance to in-house workshops </li>
            </ul>
            <h3 class="price">$150/session</h3>
          </b-col>
        </b-row>
        <b-row class="justify-content-center align-items-center" style="margin-bottom: 20px;">
          <b-col cols="11" md="4">
            <img  src="../assets/sqr_open_gym.png"  class="memImg">
          </b-col>
          <b-col  cols="11" md="6">
            <h1 class="memTtl"> Partner Training </h1>
            <ul style="text-align: left; margin-left: 10px;">
              <li> 2:1 attention with your coach </li> 
              <li> Custom programming catered to your goals </li>
              <li> Create a schedule that is convenient for you </li>
              <li> Discounted (or free) attendance to in-house workshops </li>
            </ul>
            <h3 class="price">$150+/session</h3>
          </b-col>
        </b-row>
      </b-container>
    </div>
</template> 

<script>

export default {
  name: 'MembershipTypesVertical',
  components: {

  },
  data() {
    return {

    
    }
  
  },
  methods: {

    
  }
  

}
</script>
<style scoped lang="scss">
.memTtl {
  text-align: center;
  font-family: 'Anton';
}
.memImg {
  width: 250px; 
  height: auto; 
  border-radius: 3%;
  float: right;
}
.price {
  color: #0C5854;
  font-size: 22pt;
  font-family: 'Anton';
  margin-top:-5px;
}
// for mobile displays
@media (max-width: 768px) {
.memImg {
  
  float: none;
}
}
</style>

  