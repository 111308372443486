<template>
    <div>
        <b-jumbotron class="promo" >
        <h4  style="color:white;"> Sign up for the LAUNCH program today for $169 </h4>
        <p  style="color:white;"> ($299 value) </p>
        <div >
            <b-form inline @submit="onSubmit" 
            class="justify-content-center form1" 
            >
            <b-input 
                class="input1"
                v-model="form.firstName"
                placeholder="First Name" 
                id="firstName"
                required/>
            <b-input 
                class="input1"
                v-model="form.lastName"
                placeholder="Last Name" 
                id="lastName"
                required/>
            <b-input 
                class="input2"
                v-model="form.email"
                placeholder="Email" 
                id="email"
                required/>
            <b-btn type="submit" class="btnSubmit">Submit</b-btn>
            </b-form>
        </div>
        
        </b-jumbotron>
    </div>
</template>
<script>
import emailjs from 'emailjs-com';

export default {
    name: 'PromoBanner',
    data() {
        return {
        form: {
            email: '',
            firstName: '',
            lastName: '',
            type:'Sign up for LAUNCH'
        },
        show: true,
        }
    },
    methods: {
        async onSubmit(event) {
            event.preventDefault()
            emailjs.init(process.env.VUE_APP_EMAILJS_UID);
            var name = this.form.firstName + ' ' + this.form.lastName;
            // alert(JSON.stringify(this.form))
            var templateParams = {
                name: name,
                
                email: this.form.email,
                type: this.form.type,
            }
                    
            emailjs.send(process.env.VUE_APP_EMAILJS_SERVICE_ID, 
                            process.env.VUE_APP_EMAILJS_TEMPLATE_ID, 
                            templateParams,
                                process.env.VUE_APP_EMAILJS_UID)
                .then(function(response) {
                    console.log('SUCCESS!', response.status, response.text);
                }, function(error) {
                    console.log('FAILED...', error);
                });
            // Reset our form values
            this.form.email = ''
            this.form.firstName = ''
            this.form.lastName = ''
        },
    }
}
</script>
<style scoped lang="scss">
.promo {
    background-color:black;
    background-image: linear-gradient(90deg, darkcyan, black);
    //background-image: radial-gradient(black, darkcyan, cyan  );
    padding-top: 20px;
    padding-bottom: 10px;
}
.form1 {
    display:flex; 
    flex-wrap: wrap;
    margin-top: 30px; 
    margin-bottom: 30px;
}
.input1 {
    width:20%; 
    margin-right: 5px;
}
.input2 {
    width:30%; 
    margin-right: 5px;
}
@media (max-width: 700px) {

.input1 {
    width:40%; 
    margin-right: 5px;
    margin-bottom: 5px;
}
.input2 {
    width:60%; 
    margin-right: 5px;
}

}
</style>