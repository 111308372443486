import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ContactView from '../views/ContactView.vue'
import MembershipView from '../views/MembershipView.vue'
import GettingStartedView from '../views/GettingStarted.vue'
import EventsView from '../views/EventsView.vue'
// import BlogView from '../views/BlogView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    alias: '/home'
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    component: AboutView,
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/membership',
    name: 'membership',
    component: MembershipView
  },
  {
    path: '/gettingstarted',
    name: 'gettingstarted',
    component: GettingStartedView
  },
  {
    path: '/events',
    name: 'events',
    component: EventsView
  },
  // {
  //   path: '/blog',
  //   name: 'blog',
  //   component: BlogView,
  // },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active-link-class',
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
    // always scroll to top
    return { top: 0 }
  },
})

export default router
