<template>
  <div>
    <HeaderComp />
    <b-jumbotron class="jumboDark">
      <b-container>
        <b-row class="justify-content-center" style="margin-bottom: 60px">
          <b-col cols="12" style="margin-top: 60px">
            <img src="" class="resImg" />
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row class="justify-content-center">
          <b-col cols="12">
            <h1 class="ttl">STREAMLINED START FOR RAPID RESULTS</h1>
          </b-col>
        </b-row>
        <b-row class="justify-content-center" style="margin-bottom: 30px">
          <b-col cols="8">
            <p style="text-align: center; font-size: larger">
              Whether you've never stepped foot in a gym or you are a seasoned
              athlete, we start everyone the same.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-jumbotron>

    <b-button
      @click="gs_topFunction()"
      id="gs_backtotop"
      style="background-color: darkmagenta !important"
      ><b-icon-arrow-up
    /></b-button>

    <b-row class="justify-content-center" style="padding-top: 30px">
      <p style="max-width: 90%">
        At Uplift Boulder, <b>safety is our number one priority</b>. Our only
        pieces of "stationary" equipment are the Assault Bikes and Rowers. This
        means we want to ensure you have the skills and confidence to move
        around our space. <br />
        Our number two priority is that no face goes unseen. Our coaches and
        members welcome everyone by name. Our onboarding process (LAUNCH) is
        proven to cultivate a safe and UPLIFTing environment for all.
      </p>
    </b-row>
    <b-row class="justify-content-center">
      <img src="" class="resImg" style="max-width: 500px" />
    </b-row>
    <PromoBanner />
    <FAQs />
    <GoogleReviewBanner />
    <button @click="scrollToTop" class="scroll-to-top"><b-icon-arrow-up/></button>
    <FooterComp />
  </div>
</template>

<script>
import HeaderComp from "@/components/HeaderComp.vue";
import FooterComp from "@/components/FooterComp.vue";
import PromoBanner from "@/components/PromoBanner.vue";
import GoogleReviewBanner from "@/components/GoogleReviewBanner.vue";
import FAQs from "@/components/FAQsVue.vue";

export default {
  name: "GettingStarted",
  components: {
    HeaderComp,
    FooterComp,
    PromoBanner,
    GoogleReviewBanner,
    FAQs,
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style scoped>
</style>