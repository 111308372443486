<template>
    <div>
      <b-jumbotron class="jumboDark justify-content-left align-items-center" >
        
          <div class="main_header launch_header ">
            <div class="justify-content-left align-items-center launch_info">
              <h1 class="ttl" style="text-align: left; margin-left: 5px; "> 
                  <font color="white" > UPLIFT </font>
                  <font color="#d8e030"> LAUNCH </font>
                </h1>
                <h3 style="margin-bottom: 5px; margin-left: 5px; text-align: left; text-shadow: 3px 0px 0px black;"> 
                  Our 21 Day Launch Program allows you to get a taste of what Uplift Boulder is all about. 
                  To set you up for success this program begins with a personal training session 
                  and includes 21 days of group classes and open gym access. No strings attached!
                  </h3>
            </div>
               

          </div>

        <b-container style="margin-top: 60px; margin-bottom: 100px;">  
        <!-- launch program -->
        <b-row
          class="justify-content-center align-items-center"
          style="margin-top: 10px"
        >
          <b-container class="launchBox">
            <b-row class="justify-content-center align-items-center">
              <b-col cols="11" md="10">
                <p class="desc">No Commitment</p>
                <p class="subDesc">
                  Use these 21 days to see if we are a good fit for you!
                </p>
                <p class="desc">Unlimited group class</p>
                <p class="subDesc">
                  Test out our various classes and find your favorite coach.
                </p>
                <p class="desc">Free hour of personal training</p>
                <p class="subDesc">
                  Review gym safety, proper lifting techniques and a brief intro
                  to life at Uplift!
                </p>
              </b-col>
              <b-col cols="6" md="2">

                <!-- <h2 class="price" style="text-decoration: line-through; font-size: 22px; margin: auto -5px 0 auto;">$169</h2> -->
                <h2 class="price" >$179</h2>
                
                <!-- <p style="font-style: italic; font-size: smaller;"> Limited time offer.  </p> -->
              </b-col>
            </b-row>
            <b-row class="justify-content-center align-items-center" style="margin-top: 30px;">
              <b-button 
                href="#walla-lead-form-9703"
                
              > Sign Up </b-button>

              <div 
                class="walla-widget-root" 
                data-walla-id="f918ab39-eff5-4148-ac41-4a7a243735b6"
                data-walla-page="lead-form"
                data-walla-leadformid="9703">
              </div>
          </b-row>
          </b-container>
        </b-row>
      </b-container>
    </b-jumbotron>
    </div>
</template>
<script>


export default {
  name: 'LaunchBanner',
  mounted() {
    // Dynamically load the Walla widget script when the component is mounted
    this.loadWallaScript();
  },
  methods: {
    loadWallaScript() {
      // Check if the script is already loaded to avoid reloading it multiple times
      if (document.getElementById('walla-widget-script')) return;

      const script = document.createElement('script');
      script.src = 'https://widget.hellowalla.com/loader/v1/walla-widget-loader.js';
      script.id = 'walla-widget-script';
      script.async = true;

      // Append the script to the document
      document.head.appendChild(script);
    }
  }
}
</script>
<style scoped>

.launchBox {
  /* border: 3px solid yellow;
  border-radius: 3px; */
  width: 75%;
}

.desc {
  padding: 0;
  text-align: left;
  margin: auto;
  font-size: 32px;
  font-family: 'Anton';
  font-stretch: expanded;
}
.subDesc {

  text-align: left;
  margin: auto auto 10px auto;
  color: #a4a4a4;
  font-size: 20px;
}
.price {
  color: #d8e030;
  font-size: 3rem;
  font-weight: bold;
  rotate: -10deg;
  margin: auto;
  font-family: 'Anton';
}
.launchSignUpBtn
{
  width: 180px;

}
.launch_info {
  width: 60%;
  margin-left: 2%;
}
.launch_header {
  background-image:url("../assets/img_launch1.png");
  display: flex;
  align-items: center;
  box-shadow: inset 20px -1px 16px 20px #000000cf;
  max-width:1200px;  
  margin:auto;

}
@media (max-width: 610px) {
  .launchBox {
    width: 90%;
  }
  .launch_info {
  width: 98%;
  margin-left: 5px;
}
.ttl{
  font-size:2.5rem;
}

}
</style>
