<template>
    <div>
        <b-jumbotron >
            <b-row class="justify-content-center" style="margin-top: 60px;" >
                <b-col cols="10" md="8" > 
                    <h1 class="ttl"> FIND US ON GOOGLE </h1>
                    <div class="lineBreak" style="margin-top: -10px;"></div>
                </b-col>
            </b-row>
            <!-- <b-row class="justify-content-center gReview" style="margin-top: 20px;">
                <b-col cols="8" md="4" >
                    <img src="../assets/google5Star.png" v style="width:auto; max-height: 300px;">
                </b-col>
            </b-row> -->
            <b-row class="justify-content-center gReview" >
                <b-col cols="8" md="5" >
                    <img src="../assets/googleReview1.png" style="width:auto; max-height: 300px;">
                </b-col>
                
                <b-col cols="8" md="5" >
                    <img src="../assets/googleReview2.png" style="width:auto; max-height: 300px;">
                </b-col>
                
            </b-row>
            <b-row class="justify-content-center no-gutters" style="margin-bottom: 60px;">
                <b-col cols="8" md="6" v-html="googleMap" ></b-col>
            </b-row>
        </b-jumbotron>
    </div>
</template>
<script>

export default {
    name: 'GoogleReviewBanner',
    data() {
        return {
            googleMap: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24444.424290442737!2d-105.27769797465376!3d40.01843038244362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876befec5aa08633%3A0x4183ba7970c71b21!2sUplift%20Boulder!5e0!3m2!1sen!2sus!4v1668559856177!5m2!1sen!2sus" width="100%" height="400px" style="border:0;" allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
        }
    },
    methods: {
    }
}
</script>
<style scoped lang="scss">
@media (max-width: 375px)
{
    .gReview {
        margin-left: -40px;
    }
}
</style>