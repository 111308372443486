<template>
    <div>
      <b-jumbotron class="jumboLight" >
        <b-container>
            <b-row class="justify-content-center" style="margin-top: 60px;" >
            <b-col cols="6" > 
                <h1 class="ttl"> FAQs </h1>
                <div class="lineBreak" style="margin-top: -10px;"></div>
            </b-col>
            </b-row>
            <b-row class="justify-content-center" style="text-align: left; padding-left:10%; padding-right:10%;" >
                <h3 style="font-variant-caps: all-small-caps;"> Why do I need the LAUNCH sessions? </h3>
                <p> We talked about safety.  We talked about community.  In addition to these attributes, 
                    we aim to provide an individualized approach in a group setting.  
                    To accomplish this, your coach will chat with you about your goals, conduct a movement assessment, 
                    and explain how to perform some of the more common exercises you’ll see in our programming.  
                    We all have different bodies and movement histories, in order to make your experience at Uplift 
                    Boulder as beneficial as possible we want to show you what works for you and your goals.  </p>
                <h3 style="font-variant-caps: all-small-caps;"> I've been training for years, why can't I skip LAUNCH? </h3>
                <p> No athlete, novice or professional should want to "skip" advice from trained coaches.  We
                    challenge you to name a single professional athlete that doesn't have a coach.
                </p>
                <h3 style="font-variant-caps: all-small-caps;"> Who are my coaches? </h3>
                <p> Our coaches have a wide range of specialties including certified strength and conditioning, powerlifting,
                    nutrition, pain free performance and Viking Ninja Warrior!
                </p>
                <h3 style="font-variant-caps: all-small-caps;"> Can I Change My Membership? </h3>
                <p> Of course!  You may find that your needs change over time, our coaches can help find the membership option that is best for you!
                </p>
                <!-- <b-btn style="margin-top: 60px; margin-bottom:10px; " @click="$router.push('about')"> Learn more about our coaches </b-btn> -->
            </b-row>
        </b-container>
        
          
        </b-jumbotron>
    </div>
</template>

<script>


export default {
  name: 'FAQs',
  components: {

  },
  data() {
    return {
       
    
    }
  
  },
  methods: {
    
  }
  
}
</script>