import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueMeta from 'vue-meta'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/custom.scss'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// SEO assist
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})
Vue.config.productionTip = false

Vue.mixin({
    methods: {
        scrollToTop() {
            // Smooth scroll to the top of the webpage
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          },
    }
})
new Vue({
  router,
  data: {
      window: {
          width: 0,
          height: 0
      }
  },
  created() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize);
  },
  methods: {
      handleResize() {
          this.window.width = window.innerWidth;
          this.window.height = window.innerHeight;
      }
  },
  render: h => h(App)
}).$mount('#app');

// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')
