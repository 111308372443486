<template>
  <div>
    <b-jumbotron class="jumboDark">
      <b-row class="justify-content-center">
        <b-col cols="10" md="8">
          <h1 class="ttl" style="margin-top: 60px">GROUP CLASS PRICING</h1>

          <div class="lineBreak" style="margin-top: -10px"></div>
          <p
            style="
              color: grey;
              font-style: italic;
              margin-top: -20px;
              margin-bottom: 20px;
            "
          >
            Membership charged per month. See policies for more information.
          </p>
        </b-col>
      </b-row>
      <!-- group class -->
      <b-container style="margin-bottom: 100px">
        <b-row class="justify-content-center tableRow">
          <b-col cols="4" md="6"></b-col>
          <b-col >
            <p class="memTtl">ULTIMATE</p>
          </b-col>
          <b-col >
            <p class="memTtl">CLASS ONLY</p>
          </b-col>
          <b-col >
            <p class="memTtl">8 PACK</p>
          </b-col>
        </b-row>
        <b-row class="justify-content-center tableRow">
          <b-col cols="4" md="6">
            <p class="desc">
              Unlimited small group class <br />
              (capped to 12 ppl.)
            </p>
          </b-col>
          <b-col > <b-icon-check-circle-fill class="chk" /> </b-col>
          <b-col > <b-icon-check-circle-fill class="chk" /> </b-col>
          <b-col >
            <p style="margin: auto">*8/mo</p>
          </b-col>
        </b-row>
        <b-row class="justify-content-center tableRow">
          <b-col cols="4" md="6">
            <p class="desc">24hr access to Open Gym</p>
          </b-col>
          <b-col > <b-icon-check-circle-fill class="chk" /> </b-col>
          <b-col > <b-icon-x-circle class="chk" /> </b-col>
          <b-col > <b-icon-x-circle class="chk" /> </b-col>
        </b-row>
        <b-row class="justify-content-center tableRow">
          <b-col cols="4" md="6">
            <p class="desc">Discounted quarterly personal training</p>
          </b-col>
          <b-col > <b-icon-check-circle-fill class="chk" /> </b-col>
          <b-col > <b-icon-check-circle-fill class="chk" /> </b-col>
          <b-col > <b-icon-x-circle class="chk" /> </b-col>
        </b-row>
        <b-row class="justify-content-center tableRow">
          <b-col cols="4" md="6">
            <p class="desc">Access to TrainHeroic App</p>
          </b-col>
          <b-col > <b-icon-check-circle-fill class="chk" /> </b-col>
          <b-col > <b-icon-check-circle-fill class="chk" /> </b-col>
          <b-col > <b-icon-check-circle-fill class="chk" /> </b-col>
        </b-row>
        <b-row class="justify-content-center tableRow">
          <b-col cols="4" md="6">
            <p class="desc">Bring a friend to weekend and holiday classes</p>
          </b-col>
          <b-col > <b-icon-check-circle-fill class="chk" /> </b-col>
          <b-col > <b-icon-check-circle-fill class="chk" /> </b-col>
          <b-col > <b-icon-x-circle class="chk" /> </b-col>
        </b-row>
        <b-row class="justify-content-center tableRow">
          <b-col cols="4" md="6">
            <p class="desc">Discounted (or free) attendance to in-house workshops </p>
          </b-col>
          <b-col > <b-icon-check-circle-fill class="chk" /> </b-col>
          <b-col > <b-icon-check-circle-fill class="chk" /> </b-col>
          <b-col > <b-icon-x-circle class="chk" /> </b-col>
        </b-row>
        <b-row class="justify-content-center tableRow">
          <b-col cols="3" md="6"></b-col>
          <b-col > <p class="price">$259</p> </b-col>
          <b-col > <p class="price">$229</p> </b-col>
          <b-col > <p class="price">$189</p> </b-col>
        </b-row>
      </b-container>
      
    </b-jumbotron>
  </div>
</template>
<script>
export default {
  name: "MembershipPricing",
};
</script>

<style scoped lang="scss">
.header {
  display: inline-block;
}
.price {
  color: #d8e030;
  font-size: 22pt;
  //font-family: fantasy;
  font-weight: bold;
  rotate: -10deg;
  margin: auto;
  padding: 10px;
}
.tableRow {
  //border-top: 2px solid rgb(70, 70, 70);
  border-bottom: 2px solid rgb(70, 70, 70);
  justify-content: center;
  align-items: center;
  width: 100%;
}
.desc {
  padding: 5px;
  text-align: left;
  margin: auto;
  font-size: larger;
}
.subDesc {
  padding-top: 2px;
  text-align: left;
  margin: auto;
  color: grey;
  font-size: smaller;
}
.memTtl {
  font-family: "Roboto";
  font-size: 22pt;
}
.name {
  color: black;
  font-size: larger;
  font-weight: bolder;
}
.launchBox {
  border: 3px solid #d8e030;
  border-radius: 3px;
  width: 600px;
}
.chk {
  width: 20px;
  height: auto;

}

@media (max-width: 610px) {
  .launchBox {
    width: 90%;
  }
  .price {
    font-size: 14pt;
  }
  .memTtl {
    font-size: 13pt;
  }
}
</style>