<template>
    <div>
        <b-jumbotron class="jumboDark banner" >
            <!-- <h1  class="ttl"> Member Testimonials </h1> -->
            <b-row class="justify-content-center">
                <b-col cols="10" md="6" > 
                    <h1 class="ttl"> MEMBER TESTIMONIALS </h1>
                    <div class="lineBreak" style="margin-top: -10px;"></div>
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-col cols="10" md="5" >
                    <p>
                        "Uplift is the best gym in Boulder. I’ve been attending for more than 3 
                        years and you can't find better trainers, workouts, and other members. 
                        Supportive, fun, effective!""
                    </p>
                    <p> <b>- Anna B.</b></p>
                </b-col>
                <b-col cols="10" md="5" >
                    <p>
                        "Working out here is always challenging, fun, and also very educational. 
                        Coaches really care about correct form which is the best tool 
                        for injury prevention."
                    </p>
                    <p> <b>- Tatyana H.</b></p>
                </b-col>
                <b-col cols="10" md="5" >
                    <p>
                        "I am head over heels in love with this gym. I have been training here for about 
                        2 years- I did not have any experience at a gym like this, nor did I ever 
                        build a love for a gym I had previously attended like the love I feel for this gym.
                        The programming is incredible- I feel strong, healthy, vital and my body looks better than it ever has."
                    </p>
                    <p> <b>- Brenda A.</b></p>
                </b-col>
                <b-col cols="10" md="5" >
                    <p>
                        "I just joined Uplift in late 2021 and the trainers are all amazing. 
                        The classes are small enough that there's always a trainer nearby to help,
                        the gym has all the right equipment and is spotless plus its a very comfortable 
                        place to be with a good group of people that is not intimidating at all.""
                    </p>
                    <p> <b>- Geoff S.</b></p>
                </b-col>
            </b-row>
            <!-- <b-row class="justify-content-center" style="margin-top: 40px;">
                <b-col cols="10" md="6" > 
                    
                    <div class="lineBreak" ></div>
                </b-col>
            </b-row> -->

        </b-jumbotron>
    </div>
</template>
<script>

export default {
    name: 'TestimonialsBanner',
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>
<style scoped lang="scss">
.banner {
    padding-top: 90px;
    padding-bottom: 120px;
}

</style>