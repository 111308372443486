<template>
    <div 
      class="walla-widget-root" 
      :data-walla-id="wallaId"
      :data-walla-page="wallaPage"
      :data-walla-locationid="locationId"
    ></div>
  </template>
  
  <script>
  export default {
    name: 'WallaWidget',
    props: {
      wallaId: {
        type: String,
        required: true,
      },
      wallaPage: {
        type: String,
        default: 'classes',
      },
      locationId: {
        type: String,
        required: true,
      },
    },
    mounted() {
      const scriptId = 'walla-widget-script';
      if (document.getElementById(scriptId)) return;
  
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://widget.hellowalla.com/loader/v1/walla-widget-loader.js';
      script.id = scriptId;
  
      document.getElementsByTagName('script')[0].parentNode.insertBefore(script, document.getElementsByTagName('script')[0]);
    },
  };
  </script>
  
  <style scoped>
  .walla-widget-root {
    /* Add any necessary styling here */
  }
  </style>
  